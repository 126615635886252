import { CustomElement } from 'aurelia';
import Template from './gallery.html';

class Gallery {
  constructor() {
    this.rows = [
      {items: [
        {url: '/assets/0I1A1271-Enhanced-NR.jpeg', title: 'Cold Fame (Wicket Hall // June 10, 2023)'}
      ]},
      {items: [
        {url: '/assets/DSC_0685-Edit.jpeg', title: 'The Courtney Room, Promos // April 2023)'},
        {url: '/assets/0I1A8624.jpeg', title: 'The Courtney Room, Promos // April 2023)'}
      ]},
      {items: [
        {url: '/assets/_DSC7195.jpeg', title: 'August Burns Red (Vogue // 2018)'},
        {url: '/assets/DSC_1582-Edit.jpeg', title: 'Cold Fame (The Duke Saloon // May 18, 2023)'},
        {url: '/assets/DSC_1726-Edit.jpeg', title: 'Cold Fame (Wicket Hall // June 10, 2023)'}
      ]},
      {items: [
        {url: '/assets/DSC_1777-Edit.jpeg', title: 'Cold Fame (Wicket Hall // June 10, 2023)'},
        {url: '/assets/DSC_2019-Edit.jpeg', title: 'Shale (Wicket Hall // June 10, 2023)'},
        {url: '/assets/DSC_2447-Edit-Edit.jpeg', title: 'iLLScarlet (Wicket Hall // June 10, 2023)'}
      ]},
      {items: [
        {url: '/assets/0I1A1931-Enhanced-NR.jpeg', title: 'Boy Leadfoot (Wicket Hall // June 10, 2023)'},
        {url: '/assets/0I1A2089-Enhanced-NR.jpeg', title: 'Boy Leadfoot (Wicket Hall // June 10, 2023)'},
        {url: '/assets/0I1A2246-Enhanced-NR.jpeg', title: 'Shale (Wicket Hall // June 10, 2023)'}
      ]}
    ];

    window.addEventListener('scroll', this.onScroll);
    this.scrollInterval = setInterval(this.onScroll, 500);
  }

  onScroll = (e) => {
    this.rows.forEach((row) => {
      const boundingRect = row.element.getBoundingClientRect();
      row.offTop = boundingRect.bottom <= 100;
      row.offBottom = boundingRect.top > window.innerHeight - 100;
    });
  }

  unbinding = () => {
    clearInterval(this.scrollInterval);
    window.removeEventListener('scroll', this.onScroll);
  }
}

const component = CustomElement.define({
  name: 'gallery',
  template: Template,
}, Gallery);

export default component;
