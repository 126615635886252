import { CustomElement } from 'aurelia';

import Gallery from './gallery';

import Template from './home.html';

class Home {
  constructor() {
    this.startScreen = true;
    window.addEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    window.scrollTo(0, 0);
    this.startScreen = false;

    document.body.style.overflow = 'hidden';

    setTimeout(() => {
      document.body.style.overflow = '';
      window.removeEventListener('scroll', this.onScroll);
    }, 1000);
  }
}

const component = CustomElement.define({
  name: 'home',
  template: Template,
  dependencies: [Gallery]
}, Home);

export default component;
